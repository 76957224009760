import React, { } from "react";
import { Box, Button, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { MobileWidth } from "entities/constants";
import CustomModal from "ui/components/shared/CustomModal";
import useScreenSize from "hooks/ScreenSize";
import useFontSize from "hooks/FontSize";


const ReportButtonBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.4rem',
}));

const ReportButton = ({ fontSize, text, onClick }: any) => (
  <Button color="secondary" variant='contained'
    sx={theme => (
      {
        fontSize: fontSize,
        backgroundColor: theme.palette.common.white,
        textTransform: 'none',
        justifyContent: 'flex-start',
        padding: '0.8rem 1rem',
        borderRadius: '1rem',
        textAlign: 'left',
      }
    )}
    onClick={onClick}
  >
    {text}
  </Button>
)

const ContentBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: 'column',
  flexGrow: 1,
  justifyContent: 'space-between',
  // alignItems: 'center',
  padding: '0 1.5rem 1.5rem 1.5rem',
}));

const ReportModal = ({ closeModal, handleReport }:
  {
    closeModal: () => void,
    handleReport: (note: string) => void,
  }) => {
  const { t } = useTranslation()
  const fontSize = useFontSize()
  const { width } = useScreenSize()


  const handleClick = (content: string) => {
    handleReport(encodeURIComponent(content))
    closeModal()
  }

  return (
    <CustomModal closeModal={closeModal} width={width > MobileWidth ? '32rem' : '23.75rem'} height='24rem' title='Report'>
      <ContentBox>
        <Typography sx={{ fontSize: fontSize.body }}>{t('What problem would you like to report?')}</Typography>
        <ReportButtonBox>
          <ReportButton text={t('The content seems to be wrong')} fontSize={fontSize.body} onClick={() => handleClick('The content seems to be wrong')} />
          <ReportButton text={t('The content is not sufficient as an explaination')} fontSize={fontSize.body} onClick={() => handleClick('The content is not sufficient as an explaination')} />
          <ReportButton text={t('I consider this content inappropriate')} fontSize={fontSize.body} onClick={() => handleClick('I consider this content inappropriate')} />
          <ReportButton text={t('Other')} fontSize={fontSize.body} onClick={() => handleClick('Other')} />
        </ReportButtonBox>
      </ContentBox>
    </CustomModal>
  );
};

export default ReportModal;

